import { Alert, Linking, Platform, StyleSheet, View } from "react-native";
import {
  Button,
  Card,
  Divider,
  IconButton,
  Menu,
  Modal,
  Portal,
  Text,
} from "react-native-paper";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../store/store";
import { useFullscreen } from "./use_fullscreen";
import { COLORS } from "./colors";
import {
  selectAllEnemies,
  selectFastEnemies,
  selectSlowEnemies,
} from "../store/selectors";
import { Initiative } from "../model/initiative";
import { useState } from "react";
import Markdown from "react-native-markdown-display";
import { globalStyles } from "./global_styles";

interface Props {
  onShowReleaseNotes?: () => void;
}

export default function AppBar(props: Props): JSX.Element {
  const isFullscreen = useFullscreen();
  const dispatch = useDispatch();
  const [menuVisible, setMenuVisible] = useState(false);
  const [aboutVisible, setAboutVisible] = useState(false);
  const fast = useSelector(selectFastEnemies);
  const slow = useSelector(selectSlowEnemies);

  function showAbout(): void {
    setMenuVisible(false);
    setAboutVisible(true);
  }

  function showReleaseNotes(): void {
    props.onShowReleaseNotes?.call(null);
    setMenuVisible(false);
  }

  function advanceAllCooldowns(): void {
    for (let index = 0; index < fast.length; ++index) {
      dispatch(
        actions.advanceCooldowns({ initiative: Initiative.FAST, index })
      );
    }
    for (let index = 0; index < slow.length; ++index) {
      dispatch(
        actions.advanceCooldowns({ initiative: Initiative.SLOW, index })
      );
    }
  }

  function confirmReset(): void {
    setMenuVisible(false);
    if (Platform.OS === "web") {
      if (confirm("Are you sure you want to remove all minions?")) {
        dispatch(actions.clearAll());
      }
    } else {
      Alert.alert(
        "Confirm reset",
        "Are you sure you want to remove all minions?",
        [
          { text: "No" },
          {
            text: "Yes",
            onPress() {
              dispatch(actions.clearAll());
            },
          },
        ]
      );
    }
  }

  function fullScreenButton(): JSX.Element | undefined {
    if (Platform.OS !== "web" || !document.fullscreenEnabled) {
      return undefined;
    }
    return isFullscreen ? (
      <IconButton
        icon="fullscreen-exit"
        onPress={async () => {
          await document.exitFullscreen();
        }}
      />
    ) : (
      <IconButton
        icon="fullscreen"
        onPress={async () => {
          await document
            .getElementById("root")
            .requestFullscreen({ navigationUI: "hide" });
        }}
      />
    );
  }

  return (
    <View style={[styles.container]}>
      {fullScreenButton()}
      <View style={styles.spacer} />
      <Button icon="arrow-down" onPress={advanceAllCooldowns}>
        Advance Cooldowns
      </Button>
      <Menu
        anchor={<IconButton icon="menu" onPress={() => setMenuVisible(true)} />}
        visible={menuVisible}
        onDismiss={() => setMenuVisible(false)}
      >
        <Menu.Item leadingIcon="restart" title="Reset" onPress={confirmReset} />
        <Divider />
        <Menu.Item
          leadingIcon="information-outline"
          title="About"
          onPress={showAbout}
        />
        <Menu.Item
          leadingIcon="newspaper-variant-outline"
          title="Release Notes"
          onPress={showReleaseNotes}
        />
      </Menu>

      <Portal>
        <Modal
          style={{ alignItems: "center" }}
          visible={aboutVisible}
          onDismiss={() => setAboutVisible(false)}
        >
          <Card style={styles.about}>
            <Card.Content>
              <Text>Board with Paint on:</Text>
              <View style={styles.links}>
                <Button
                  icon="youtube"
                  onPress={() =>
                    Linking.openURL(
                      "https://www.youtube.com/@boardwithpaint?sub_confirmation=1"
                    )
                  }
                >
                  Youtube
                </Button>
                <Button
                  icon="instagram"
                  onPress={() =>
                    Linking.openURL("https://www.instagram.com/boardwithpaint")
                  }
                >
                  Instagram
                </Button>
                <Button
                  icon="facebook"
                  onPress={() =>
                    Linking.openURL("https://www.facebook.com/boardwithpaint")
                  }
                >
                  Facebook
                </Button>
                <Button
                  icon="web"
                  onPress={() =>
                    Linking.openURL("https://www.boardwithpaint.com")
                  }
                >
                  Web
                </Button>
              </View>
              <Markdown>
                {`
# About Divine Helper

## Divine Helper by [Board with Paint](https://www.youtube.com/@boardwithpaint?sub_confirmation=1)
Version 1.2


This application is provided for free and without any
warranty. Use it at your own risk. Board with Paint is
not responsible for any issues caused by the use of this
application.

### Privacy Policy

Any information collected will **never** be sold nor used
for any marketing purpose.

*This application uses cookies, local storage, and tracks
anonymous analytics. Use of this application indicates your
consent.*

Copyright ©2024 Board With Paint. All rights reserved.
`}
              </Markdown>
            </Card.Content>
          </Card>
        </Modal>
      </Portal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    flexDirection: "row",
    shadowColor: COLORS.black,
    shadowOffset: { height: 5, width: 0 },
    shadowOpacity: 0.5,
    shadowRadius: 10,
    alignItems: "center",
  },

  about: {
    // width: "75%",
  },

  links: {
    flexDirection: "row",
    alignItems: "center",
  },

  removing: {
    backgroundColor: "rgba(255,0,0,0.5)",
  },

  spacer: {
    flex: 1,
  },
});
