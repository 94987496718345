import { createSelector, lruMemoize } from "@reduxjs/toolkit";
import { EnemyState, Game, LETTERS, Letter } from "../model/game";

export function selectState(state: Game): Game {
  return state;
}

export function selectLoading(state: Game): boolean {
  return !!state.loading;
}

export function selectSawReleaseNotes(state: Game): string | undefined {
  return state.sawReleaseNotes;
}

export function selectFastEnemies(state: Game): EnemyState[] {
  return state.fast;
}

export function selectSlowEnemies(state: Game): EnemyState[] {
  return state.slow;
}

export const selectAllEnemies = createSelector(
  [selectFastEnemies, selectSlowEnemies],
  (fast, slow): EnemyState[] => {
    return [...fast, ...slow];
  }
);

export const selectUsedModifiers = createSelector(
  [selectAllEnemies],
  (enemies) => enemies.flatMap((e) => e.modifiers ?? [])
);

export const selectAvailableLetters: (state: Game) => ReadonlySet<Letter> =
  createSelector(
    [selectAllEnemies],
    (allEnemies): ReadonlySet<Letter> => {
      const letters = new Set(LETTERS);
      allEnemies.forEach((e) => letters.delete(e.letter));
      return letters;
    },
    {
      memoize: lruMemoize,
      memoizeOptions: {
        resultEqualityCheck: (
          a: ReadonlySet<Letter>,
          b: ReadonlySet<Letter>
        ) => {
          return a.size === b.size && [...a.values()].every((v) => b.has(v));
        },
      },
    } as any
  );
