import {
  View,
  StyleSheet,
  ViewStyle,
  StyleProp,
  useWindowDimensions,
  Pressable,
} from "react-native";
import { EnemyState } from "../model/game";
import { Initiative } from "../model/initiative";
import { Portal, Modal } from "react-native-paper";
import { useState } from "react";
import FullCardView from "./FullCardView";
import {
  ACTUAL_IMAGE_HEIGHT,
  ACTUAL_IMAGE_WIDTH,
  globalStyles,
  HW_IMAGE_ASPECT,
} from "./global_styles";
import MinionCard from "./MinionCard";
import MinionMarker from "./MinionMarker";
import { getEnemy } from "../db";
import { isEnemy } from "../model/enemy";
import { MinionObj } from "./minion_obj";

const SCALE_FACTOR = 0.4;
const IMAGE_WIDTH = Math.round(ACTUAL_IMAGE_WIDTH * SCALE_FACTOR);
const IMAGE_HEIGHT = Math.round(ACTUAL_IMAGE_HEIGHT * SCALE_FACTOR);

interface Props {
  style?: StyleProp<ViewStyle>;
  enemy: EnemyState;
  initiative: Initiative;
  index: number;
}

export default function EnemyContainer(props: Props): React.JSX.Element {
  const { height } = useWindowDimensions();
  const [showFullView, setShowFullView] = useState(false);
  const minion = new MinionObj(getEnemy(props.enemy.id), props.enemy);

  function clickCard(): void {
    setShowFullView(true);
  }

  const minHeight = height / 3;
  const imageSizeStyle = {
    width: minHeight / HW_IMAGE_ASPECT,
    height: minHeight,
  };
  const cooldownStyle = {
    minWidth: imageSizeStyle.width / 2,
    height: imageSizeStyle.height,
  };

  return (
    <View style={[props.style, styles.container, globalStyles.flex1]}>
      <MinionMarker
        minion={minion}
        index={props.index}
        initiative={props.initiative}
      />
      <Pressable style={globalStyles.flex1} onPress={clickCard}>
        <MinionCard
          style={globalStyles.flex1}
          initiative={props.initiative}
          index={props.index}
          minion={minion}
        />
      </Pressable>

      <Portal>
        <Modal visible={showFullView} onDismiss={() => setShowFullView(false)}>
          <Pressable onPress={() => setShowFullView(false)}>
            <FullCardView index={props.index} initiative={props.initiative} />
          </Pressable>
        </Modal>
      </Portal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.2)",
    borderRadius: 5,
  },

  bottomContainer: {
    flex: 1,
    flexDirection: "row",
  },

  image: {},

  remove: {
    fontSize: 30,
    color: "red",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.5)",
    height: "100%",
    width: "100%",
    textAlign: "center",
    padding: 10,
  },
});
