export const ICONS = {
  air_damage: require("../assets/images/icons/air_damage.png"),
  ap: require("../assets/images/icons/ap.png"),
  auto_success: require("../assets/images/icons/auto_success.png"),
  blue_die: require("../assets/images/icons/blue_die.png"),
  direct_damage: require("../assets/images/icons/direct_damage.png"),
  earth_damage: require("../assets/images/icons/earth_damage.png"),
  fire_damage: require("../assets/images/icons/fire_damage.png"),
  hit: require("../assets/images/icons/hit.png"),
  hp: require("../assets/images/icons/hp.png"),
  level_bonus: require("../assets/images/icons/level_bonus.png"),
  magic_armor: require("../assets/images/icons/magic_armor.png"),
  physical_armor: require("../assets/images/icons/physical_armor.png"),
  physical_damage: require("../assets/images/icons/physical_damage.png"),
  poison_damage: require("../assets/images/icons/poison_damage.png"),
  red_die: require("../assets/images/icons/red_die.png"),
  source: require("../assets/images/icons/source.png"),
  special: require("../assets/images/icons/special.png"),
  target: require("../assets/images/icons/target.png"),
  target_closest: require("../assets/images/icons/target_closest.png"),
  target_least: require("../assets/images/icons/target_least.png"),
  target_most: require("../assets/images/icons/target_most.png"),
  water_damage: require("../assets/images/icons/water_damage.png"),
  white_die: require("../assets/images/icons/white_die.png"),
} as const;