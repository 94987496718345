import { store } from "./store/store";
import React, { StrictMode, useState } from "react";
import { Provider } from "react-redux";
import MainView from "./components/MainView";
import { StatusBar } from "expo-status-bar";
import { Provider as PaperProvider } from "react-native-paper";
import { AppContext, defaultAppContext } from "./components/AppContext";
import { useFonts } from "expo-font";
import { FONTS } from "./bundles/fonts";
import {
  Platform,
  SafeAreaView,
  StyleProp,
  Text,
  ViewStyle,
} from "react-native";
import { globalStyles } from "./components/global_styles";

export default function App(): React.JSX.Element {
  const [appContext, setAppContext] = useState(defaultAppContext);
  const [fontsLoaded, fontError] = useFonts(FONTS);
  if (fontError) {
    throw fontError;
  }
  if (!fontsLoaded) {
    return <Text>Loading...</Text>;
  }

  // Force type safety for web.
  const webStyle = {
    height: "100vh",
    overflow: "hidden",
  } as unknown as StyleProp<ViewStyle>;

  return (
    <SafeAreaView
      style={[globalStyles.flex1, Platform.OS === "web" ? webStyle : null]}
    >
      <StrictMode>
        <Provider store={store}>
          <PaperProvider>
            <AppContext.Provider value={{ appContext, setAppContext }}>
              <MainView />
            </AppContext.Provider>
          </PaperProvider>
          <StatusBar style="auto" />
        </Provider>
      </StrictMode>
    </SafeAreaView>
  );
}
