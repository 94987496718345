import { LinearGradient } from "expo-linear-gradient";
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native";
import interpolate from "color-interpolate";
import { healthInterpolator } from "./global_styles";

interface Props {
  style?: StyleProp<ViewStyle>;
  max: number;
  current: number;
}

export default function HealthBar(props: Props): JSX.Element {
  const { current, max } = props;
  const widthRatio = current / max;
  const widthPct = Math.round(100 * widthRatio);
  return (
    <LinearGradient
      style={[props.style, styles.container]}
      colors={["#333", "#666"]}
    >
      <View
        style={[
          styles.filled,
          {
            width: `${widthPct}%`,
            backgroundColor: healthInterpolator(widthRatio),
          },
        ]}
      />
    </LinearGradient>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderRadius: 3,
    margin: 3,
  },

  filled: {
    height: "100%",
    backgroundColor: "green",
    borderRadius: 3,
  },
});
