import { StyleProp, ImageStyle, StyleSheet, Image } from "react-native";

export function icon(
  source: any,
  style?: StyleProp<ImageStyle>,
  key?: React.Key
): JSX.Element {
  return <Image key={key} source={source} style={[styles.icon, style]} />;
}

const styles = StyleSheet.create({
  icon: {
    width: 20,
    height: 20,
    verticalAlign: "middle",
    resizeMode: "contain",
  },
});

export const iconStyle = styles.icon;
