import { createContext } from "react";

export interface AppContextType {}

export const defaultAppContext: AppContextType = {};

export const AppContext = createContext<{
  appContext: AppContextType;
  setAppContext: React.Dispatch<React.SetStateAction<AppContextType>>;
}>({ appContext: defaultAppContext, setAppContext: () => {} });
