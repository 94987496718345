import {
  Image,
  Pressable,
  ScrollView,
  StyleProp,
  StyleSheet,
  useWindowDimensions,
  View,
  ViewStyle,
} from "react-native";
import {
  Button,
  Divider,
  Icon,
  IconButton,
  Menu,
  Modal,
  Portal,
  Text,
} from "react-native-paper";
import { EnemyState, hasStatus, minionMaxHp } from "../model/game";
import { getEnemy, getModifier } from "../db";
import { ENEMY_IMAGES } from "../bundles/enemy_images";
import RichText from "./RichText";
import { LinearGradient } from "expo-linear-gradient";
import { BOLD_FONT, LIGHT_FONT, MAIN_FONT } from "../bundles/fonts";
import { ICONS } from "../bundles/icons";
import { attackTypeText } from "../model/attack_type";
import { Targeting } from "../model/targeting";
import { DiceColor } from "../model/dice";
import Effect from "./Effect";
import { useState } from "react";
import CooldownPicker from "./CooldownPicker";
import { STATUS_STRINGS, StatusEffect } from "../model/status_effect";
import { useDispatch } from "react-redux";
import { actions } from "../store/store";
import {
  getInitiativeLabel,
  Initiative,
  toggleInitiative,
} from "../model/initiative";
import HealthBar from "./HealthBar";
import { globalStyles, healthInterpolator } from "./global_styles";
import { STATUS_IMAGES } from "../bundles/status_images";
import { ImageStyle } from "expo-image";
import ModifierPicker from "./ModifierPicker";
import { icon, iconStyle } from "./GameIcon";
import { COLORS } from "./colors";
import { TEXT } from "./text";
import { DamageType } from "../model/damage_type";
import { Modifier } from "../model/modifier";
import { MinionObj } from "./minion_obj";

const chickenImage = require("../assets/images/portraits/chicken.png");

interface Props {
  initiative: Initiative;
  index: number;
  style?: StyleProp<ViewStyle>;
  minion: MinionObj;
}

export default function MinionCard(props: Props): JSX.Element {
  const dimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const { style, initiative, index, minion } = props;
  const [pickerVisible, setPickerVisible] = useState(false);
  const [modsVisible, setModsVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  function statusChange(status: StatusEffect, turn: number | undefined): void {
    if (turn !== undefined) {
      dispatch(actions.addCooldown({ initiative, index, status, turn }));
    } else {
      dispatch(actions.removeCooldown({ initiative, index, status }));
    }
  }

  const sortedCooldowns = [...minion.cooldowns];
  sortedCooldowns.sort((a, b) => {
    const cmp = a.turns - b.turns;
    if (cmp !== 0) {
      // Invert the comparison because we want larger numbers first.
      return -cmp;
    }
    const aName = STATUS_STRINGS.get(a.status);
    const bName = STATUS_STRINGS.get(b.status);
    return aName.localeCompare(bName);
  });

  const minionText = [
    ...minion.text,
    ...minion.modifiers.map((mod) => {
      return [`&${mod.name}`, getModifierBonusText(mod), ...(mod.text ?? [])];
    }),
  ].flat();

  const armor =
    minion.armor + minion.modifiers.reduce((a, c) => a + (c.armor ?? 0), 0);
  const magicArmor =
    minion.magicArmor +
    minion.modifiers.reduce((a, c) => a + (c.magicArmor ?? 0), 0);
  const damageType =
    minion.modifiers.findLast((m) => !!m.damageType)?.damageType ??
    minion.damageType;
  const range =
    minion.modifiers.findLast((m) => !!m.rangeOverride)?.rangeOverride ??
    minion.range;
  const diceNum =
    minion.attackRoll.num +
    minion.modifiers.reduce(
      // Limit max to 5, per card text.
      (a, c) => Math.min(5 - minion.attackRoll.num, a + (c.diceBonus ?? 0)),
      0
    );

  function diceMod(d: number): number {
    let mod = 0;
    if (minion.isFrozen) mod -= 1;
    if (minion.isStunned) mod -= 1;
    return Math.max(0, d + mod);
  }

  function removeMinion(): void {
    dispatch(actions.removeEnemy({ initiative, index }));
    setMenuVisible(false);
  }

  function moveMinion(): void {
    dispatch(actions.swapInitiative({ initiative, index }));
    setMenuVisible(false);
  }

  function addNightmare(): void {
    setModsVisible(true);
    setMenuVisible(false);
  }

  function removeNightmare(): void {
    for (let mod of minion.modifiers) {
      dispatch(
        actions.removeModifier({ index, initiative, modifierId: mod.id })
      );
    }
    setMenuVisible(false);
  }

  return (
    <View style={[props.style, styles.root]}>
      <HealthBarWithName
        name={minion.name}
        currentHp={minion.currentHp}
        maxHp={minion.maxHp}
        isBleeding={minion.isBleeding}
        isBurning={minion.isBurning}
        isPoisoned={minion.isPoisoned}
        isBoss={minion.isBoss}
      />
      <View style={[styles.upperPanel]}>
        <Image
          style={[styles.portrait]}
          source={
            minion.isChicken
              ? chickenImage
              : ENEMY_IMAGES[`${minion.id}_portrait`]
          }
        />
        <View style={[styles.rightSide, { zIndex: 1000 }]}>
          <View style={styles.rightTop}>
            {minion.hpLossPerTurn > 0 ? (
              <Text style={styles.hpLoss}>
                -{minion.hpLossPerTurn} {icon(ICONS.hp, styles.hpLossIcon)} /
                Turn
              </Text>
            ) : null}
            <Text style={styles.level}>Lvl. {minion.level}</Text>
          </View>
          <Menu
            anchor={
              <IconButton
                icon="dots-vertical"
                mode="contained"
                size={14}
                iconColor="#fff"
                containerColor="#942210cc"
                onPress={() => setMenuVisible(true)}
              />
            }
            visible={menuVisible}
            onDismiss={() => setMenuVisible(false)}
          >
            <Menu.Item
              leadingIcon="account-remove"
              title="Remove Minion"
              onPress={removeMinion}
            ></Menu.Item>
            <Menu.Item
              leadingIcon={
                initiative === Initiative.FAST
                  ? "arrow-down-thick"
                  : "arrow-up-thick"
              }
              title={`Move to ${getInitiativeLabel(
                toggleInitiative(initiative)
              )}`}
              onPress={moveMinion}
            />
            <Divider />
            <Menu.Item
              leadingIcon="skull"
              title="Add Nightmare Bonus"
              onPress={addNightmare}
            ></Menu.Item>
            <Menu.Item
              disabled={!minion.modifiers?.length}
              leadingIcon="delete-alert"
              title="Remove all Nightmare Bonuses"
              onPress={removeNightmare}
            ></Menu.Item>
            {minion.modifiers?.length ? <Divider /> : null}
            {minion.modifiers
              ? minion.modifiers.map((mod) => (
                  <Menu.Item
                    key={mod.id}
                    leadingIcon="delete"
                    title={`Remove "${mod.name}"`}
                    onPress={() => {
                      dispatch(
                        actions.removeModifier({
                          index,
                          initiative,
                          modifierId: mod.id,
                        })
                      );
                      setMenuVisible(false);
                    }}
                  ></Menu.Item>
                ))
              : null}
          </Menu>
        </View>
        <View style={styles.leftPanel}>
          <View style={{ flexDirection: "row" }}>
            <LinearGradient
              colors={["rgba(0,0,0,.85)", "rgba(64,64,64,.85)"]}
              style={styles.healthStats}
            >
              <View style={styles.healthStatsRow}>
                {icon(ICONS.hp)}
                <Text style={styles.healthStatsText}>
                  <Text
                    style={[
                      {
                        color: healthInterpolator(
                          minion.currentHp / minion.maxHp
                        ),
                      },
                      styles.currentHp,
                    ]}
                  >
                    {minion.currentHp}
                  </Text>{" "}
                  / {minion.maxHp}
                </Text>
              </View>
              <View style={styles.healthStatsRow}>
                {minion.isSundered
                  ? icon(STATUS_IMAGES.get(StatusEffect.SUNDERED))
                  : icon(ICONS.physical_armor)}
                {isNaN(armor) ? (
                  <Text style={styles.healthStatsText}>*</Text>
                ) : (
                  adjustedStat(minion.isSundered, armor, (a) =>
                    Math.max(0, a - 2)
                  )
                )}
              </View>
              <View style={styles.healthStatsRow}>
                {icon(ICONS.magic_armor)}
                <Text style={styles.healthStatsText}>
                  {isNaN(magicArmor) ? "*" : magicArmor}
                </Text>
              </View>
            </LinearGradient>
          </View>

          {minion.isBoss ? (
            <LinearGradient
              style={[styles.tacticalStats, { height: 120 }]}
              colors={["rgba(100, 92, 59, .85)", "rgba(27, 36, 28, .85)"]}
              start={[0, 0.5]}
              end={[1, 0.5]}
            >
              <Text style={styles.tacticalStatsText}>
                {icon(ICONS.ap)} {minion.ap ?? "*"}
              </Text>
            </LinearGradient>
          ) : (
            <LinearGradient
              style={styles.tacticalStats}
              colors={["rgba(100, 92, 59, .85)", "rgba(27, 36, 28, .85)"]}
              start={[0, 0.5]}
              end={[1, 0.5]}
            >
              <View style={styles.targetingIcons}>
                {minion.isDominated
                  ? [
                      icon(ICONS.target),
                      icon(STATUS_IMAGES.get(StatusEffect.DOMINATED)),
                    ]
                  : targetingIcons(minion.targeting)}
              </View>
              <Text style={styles.tacticalStatsText}>
                <Text
                  style={[
                    styles.tacticalStatsText,
                    minion.isKnocked ? globalStyles.strike : null,
                  ]}
                >
                  Move:
                </Text>{" "}
                {minion.isKnocked
                  ? icon(STATUS_IMAGES.get(StatusEffect.KNOCKDOWN))
                  : adjustedStat(
                      minion.isFrozen,
                      minion.move,
                      (m) => Math.max(0, minion.move - 1),
                      styles.tacticalStatsText
                    )}
              </Text>
              <Text style={styles.tacticalStatsText}>
                Range:{" "}
                {range === undefined
                  ? "*"
                  : adjustedStat(
                      minion.isBlinded,
                      range,
                      (r) => 0,
                      styles.tacticalStatsText
                    )}
              </Text>
              <Text style={styles.tacticalStatsText}>
                {attackTypeText(minion.attackType)}
                {damageTypeIcon(damageType)}
              </Text>

              <View style={styles.dice}>
                {minion.isChicken ? (
                  <Text style={[styles.diceText, { fontSize: 12 }]}>
                    {icon(STATUS_IMAGES.get(StatusEffect.CHICKEN), {
                      resizeMode: "contain",
                    })}
                    BAWK!
                    {icon(STATUS_IMAGES.get(StatusEffect.CHICKEN), {
                      resizeMode: "contain",
                    })}
                  </Text>
                ) : (
                  <Text style={styles.diceText}>
                    {adjustedStat(
                      minion.isFrozen || minion.isStunned,
                      diceNum,
                      diceMod,
                      styles.diceText
                    )}{" "}
                    {diceIcon(minion.attackRoll.color)}
                    {minion.isShocked ? (
                      <Text style={[styles.diceText, styles.adjusted]}>
                        {bonusText(" ", minion.attackRoll.bonus - 2)}
                      </Text>
                    ) : (
                      bonusText(" ", minion.attackRoll.bonus)
                    )}
                  </Text>
                )}
              </View>
            </LinearGradient>
          )}
        </View>
        <View style={styles.cooldownContainer}>
          <View style={styles.cooldownButton}>
            {!sortedCooldowns.length ? (
              <IconButton
                mode="contained"
                icon="plus"
                size={14}
                onPress={() => setPickerVisible(true)}
              />
            ) : null}
          </View>
          {minion.cooldowns.length ? (
            <Pressable
              style={styles.cooldowns}
              onPress={() => setPickerVisible(true)}
            >
              {sortedCooldowns.map(({ status, turns }) => (
                <Effect key={status} status={status} turns={turns} />
              ))}
              <IconButton
                mode="contained"
                icon="arrow-down"
                size={14}
                onPress={() =>
                  dispatch(actions.advanceCooldowns({ index, initiative }))
                }
              />
            </Pressable>
          ) : null}
        </View>
      </View>
      <LinearGradient
        colors={["#140806", "#5c2110", "#140806"]}
        start={[0, 0.5]}
        end={[1, 0.5]}
        style={[styles.bottomPanel, globalStyles.flex1]}
      >
        <ScrollView style={styles.textScroller}>
          <RichText
            style={[TEXT.richText, globalStyles.flex1]}
            text={minionText}
            headingStyle={TEXT.heading}
          />
        </ScrollView>
      </LinearGradient>

      {minion.currentHp <= 0 && (
        <View style={styles.removeContainer}>
          <Button
            mode="contained"
            onPress={() => removeMinion()}
            theme={{ colors: { primary: "#f00c" } }}
          >
            <View style={styles.removeButton}>
              <Icon source="skull" size={90} color="#ccc" />
              <Text style={styles.removeButtonText}>Remove</Text>
            </View>
          </Button>
        </View>
      )}

      <Portal>
        <Modal
          key="cooldownpicker"
          style={styles.modal}
          visible={pickerVisible}
          onDismiss={() => setPickerVisible(false)}
        >
          <CooldownPicker
            style={{ maxWidth: dimensions.width * 0.8 }}
            cooldowns={minion.cooldowns}
            onChange={statusChange}
            isBoss={minion.isBoss}
          />
        </Modal>

        <Modal
          key="modpicker"
          visible={modsVisible}
          onDismiss={() => setModsVisible(false)}
        >
          <ModifierPicker
            onSelect={(mod) => {
              dispatch(
                actions.addModifier({ index, initiative, modifierId: mod.id })
              );
              setModsVisible(false);
            }}
          />
        </Modal>
      </Portal>
    </View>
  );
}

const BORDER = {
  borderColor: "#c79648",
  borderBlockColor: "#eae8b5",
  borderWidth: 2,
  borderRadius: 3,
} as const;

const LEFT_FLEX = 75;
const RIGHT_FLEX = 100;

const styles = StyleSheet.create({
  root: {
    width: 250,
    backgroundColor: "rgba(128,128,128,0.5)",
  },

  minionNameContainer: {
    position: "relative",
    flexDirection: "row",
    padding: 4,
    justifyContent: "center",
    ...BORDER,
  },

  healthBar: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },

  minionName: {
    color: COLORS.white,
    fontFamily: BOLD_FONT,
    flex: 1,
    fontSize: 16,
    padding: 2,
    textShadowColor: COLORS.black,
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 5,
  },

  bossName: {
    color: COLORS.lightGold,
  },

  minionStatus: {
    position: "relative",
    resizeMode: "contain",
  },

  minionStatusIcon: {
    position: "absolute",
    top: 0,
  },

  upperPanel: {
    flexDirection: "row",
  },

  leftPanel: {
    flex: LEFT_FLEX,
  },

  portrait: {
    width: 200,
    height: 200,
    position: "absolute",
    right: 0,
  },

  hpLoss: {
    fontFamily: MAIN_FONT,
    color: COLORS.white,
    fontSize: 16,
    flex: 1,
    padding: 4,
  },

  hpLossIcon: {
    height: 10,
    width: 10,
    resizeMode: "cover",
  },

  rightSide: {
    position: "absolute",
    right: 0,
    alignItems: "flex-end",
  },

  rightTop: {
    flexDirection: "row",
    alignItems: "baseline",
    gap: 4,
    backgroundColor: "rgba(128,0,0,.8)",
  },

  level: {
    color: COLORS.white,
    fontFamily: MAIN_FONT,

    backgroundColor: COLORS.darkRed,
    padding: 4,
    paddingLeft: 6,
    paddingRight: 6,
    verticalAlign: "middle",
    ...BORDER,
    borderWidth: 1,
    borderTopWidth: 0,
    borderBottomLeftRadius: 8,
  },

  healthStats: {
    flex: 1,
    gap: 6,
    padding: 6,
    alignItems: "center",
    ...BORDER,
  },

  healthStatsRow: {
    flexDirection: "row",
    gap: 6,
  },

  healthStatsText: {
    color: COLORS.white,
    fontFamily: MAIN_FONT,
    fontSize: 20,
  },

  currentHp: {
    textShadowColor: COLORS.darkShadow,
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 1,
  },

  tacticalStats: {
    alignItems: "center",
    gap: 6,
    padding: 6,
    borderColor: COLORS.darkBorder,
    borderBlockColor: COLORS.lightBorder,
    borderWidth: 2,
  },

  tacticalStatsText: {
    color: COLORS.white,
    fontFamily: MAIN_FONT,
    fontSize: 17,
  },

  dice: {},

  cooldownContainer: {
    flex: RIGHT_FLEX,
    alignContent: "flex-end",
    justifyContent: "flex-end",
  },

  cooldownButton: {
    alignItems: "flex-end",
  },

  cooldowns: {
    padding: 4,
    gap: 4,
    backgroundColor: "rgba(0,0,0,0.5)",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "flex-end",
    justifyContent: "flex-end",
  },

  diceText: {
    color: COLORS.white,
    fontFamily: BOLD_FONT,
    fontSize: 20,
  },

  bottomPanel: {},

  textScroller: {
    borderColor: COLORS.darkBorder,
    borderBlockColor: COLORS.lightBorder,
    borderWidth: 2,
  },

  targetingIcons: {
    flexDirection: "row",
    gap: 3,
  },

  modal: {
    alignItems: "center",
    justifyContent: "center",
  },

  adjusted: {
    color: "#f66",
  },

  inactive: {
    opacity: 0.33,
    marginLeft: 4,
    fontSize: 12,
  },

  removeContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  removeButton: {
    alignItems: "center",
  },

  removeButtonText: {
    color: "#ccc",
    fontFamily: BOLD_FONT,
    fontSize: 24,
  },
});

const ICON_MAP: ReadonlyMap<Targeting, any> = new Map<Targeting, any | any[]>([
  [Targeting.CLOSEST, ICONS.target_closest],
  [Targeting.HIGHEST_ARMOR, [ICONS.target_most, ICONS.physical_armor]],
  [Targeting.HIGHEST_HP, [ICONS.target_most, ICONS.hp]],
  [Targeting.HIGHEST_MAGIC_ARMOR, [ICONS.target_most, ICONS.magic_armor]],
  [Targeting.HIGHEST_SOURCE, [ICONS.target_most, ICONS.source]],
  [Targeting.LEAST_ORIGINS, [ICONS.target_least /* TODO: need origins icon?*/]],
  [Targeting.LOWEST_ARMOR, [ICONS.target_least, ICONS.physical_armor]],
  [Targeting.LOWEST_HP, [ICONS.target_least, ICONS.hp]],
  [Targeting.LOWEST_MAGIC_ARMOR, [ICONS.target_least, ICONS.magic_armor]],
  [Targeting.LOWEST_SOURCE, [ICONS.target_least, ICONS.source]],
]);

function targetingIcons(targeting: Targeting): JSX.Element[] {
  if (targeting === Targeting.SPECIAL) {
    return [
      <Image key="img" style={iconStyle} source={ICONS.target} />,
      <Text key="txt" style={styles.tacticalStatsText}>
        Special
      </Text>,
    ];
  }

  const icons = [ICONS.target, ...[ICON_MAP.get(targeting)!].flat()];

  return icons.map((icon, i) => (
    <Image key={i} style={iconStyle} source={icon} />
  ));
}

function diceIcon(diceColor: DiceColor): JSX.Element {
  let icon: any;
  switch (diceColor) {
    case "W":
      icon = ICONS.white_die;
      break;
    case "B":
      icon = ICONS.blue_die;
      break;
    case "R":
      icon = ICONS.red_die;
      break;
  }
  return <Image style={iconStyle} source={icon} />;
}

function bonusText(prefix: string, bonus: number | undefined): string {
  if (bonus === undefined) {
    return "";
  }
  if (bonus >= 0) {
    return `${prefix}+ ${bonus}`;
  }
  return `${prefix}- ${Math.abs(bonus)}`;
}

function adjustedStat(
  isAdjusted: boolean,
  baseValue: number,
  adjuster: (base: number) => number,
  style = styles.healthStatsText
): JSX.Element {
  return (
    <Text style={[style, isAdjusted ? styles.adjusted : null]}>
      {isAdjusted ? adjuster(baseValue) : baseValue}
      {isAdjusted ? (
        <Text style={[style, styles.inactive]}>({baseValue})</Text>
      ) : null}
    </Text>
  );
}

const DAMAGE_TYPE_ICONS: ReadonlyMap<DamageType, any> = new Map([
  [DamageType.AIR, ICONS.air_damage],
  [DamageType.DIRECT, ICONS.direct_damage],
  [DamageType.EARTH, ICONS.earth_damage],
  [DamageType.FIRE, ICONS.fire_damage],
  [DamageType.PHYSICAL, ICONS.physical_damage],
  [DamageType.POISON, ICONS.poison_damage],
  [DamageType.WATER, ICONS.water_damage],
]);
function damageTypeIcon(damageType: DamageType): JSX.Element {
  return icon(DAMAGE_TYPE_ICONS.get(damageType));
}

function getModifierBonusText(mod: Modifier): string {
  const text = [];
  if (mod.hp) {
    text.push(`+${mod.hp} [hp]`);
  }
  if (mod.armor) {
    text.push(`+${mod.armor} [physical armor]`);
  }
  if (mod.magicArmor) {
    text.push(`+${mod.magicArmor} [magic armor]`);
  }
  return text.join(" ");
}

interface HealthBarWithNameProps {
  name: string;
  currentHp: number;
  maxHp: number;
  isPoisoned: boolean;
  isBurning: boolean;
  isBleeding: boolean;
  isBoss: boolean;
}

export function HealthBarWithName(props: HealthBarWithNameProps): JSX.Element {
  return (
    <View style={styles.minionNameContainer}>
      <HealthBar
        style={styles.healthBar}
        current={props.currentHp}
        max={props.maxHp}
      />
      <Text style={[styles.minionName, props.isBoss ? styles.bossName : {}]}>
        {props.name}
      </Text>
      {minionStatus(props.isPoisoned, props.isBurning, props.isBleeding)}
    </View>
  );
}

function minionStatus(
  isPoisoned: boolean,
  isBurning: boolean,
  isBleeding: boolean
): JSX.Element {
  const icons = [
    isPoisoned ? StatusEffect.POISON : null,
    isBurning ? StatusEffect.BURNING : null,
    isBleeding ? StatusEffect.BLEEDING : null,
  ]
    .filter((ic) => ic !== null)
    .map((ic, i) =>
      icon(
        STATUS_IMAGES.get(ic),
        {
          ...styles.minionStatusIcon,
          right: i * 15,
        },
        i
      )
    );

  return <View style={styles.minionStatus}>{icons}</View>;
}
