import { BaseEnemy } from "./enemy";

export interface Boss extends BaseEnemy {
  fullName: string;
  hp: [number, number, number];
  ap?: number;
  armor?: number;
  magicArmor?: number;
  type: "boss";
}

export function isBoss(obj: any): obj is Boss {
  return obj.type === "boss";
}

export function hpForBoss(numPlayers: number, boss: Boss): number {
  return boss.hp[numPlayers - 2];
}
