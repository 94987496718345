import {
  Image,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from "react-native";
import { Text } from "react-native-paper";
import { ICONS } from "../bundles/icons";
import { STATUS_IMAGES } from "../bundles/status_images";
import { STATUS_STRINGS, STRING_STATUSES } from "../model/status_effect";
import { COLORS } from "./colors";
import { BOLD_FONT } from "../bundles/fonts";

interface Props {
  style?: StyleProp<ViewStyle | TextStyle>;
  headingStyle?: StyleProp<TextStyle>;
  text?: string | string[];
  scrollable?: boolean;
}

export default function RichText(props: Props): JSX.Element {
  const lines = Array.isArray(props.text) ? props.text : [props.text ?? ""];
  const parse = parseLines(lines);

  const extraTextStyle = extractExtraTextStyle(props.style);

  return (
    <View style={[props.style, styles.container]}>
      {parse.map((p, i) =>
        renderParses(i, p, extraTextStyle, props.headingStyle as TextStyle)
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {},

  text: {
    color: COLORS.white,
    verticalAlign: "middle",
  },

  icon: {
    width: 16,
    height: 16,
    backgroundColor: "transparent",
    verticalAlign: "middle",
  },

  status: {
    color: COLORS.gold,
    textTransform: "uppercase",
    fontWeight: "bold",
  },

  token: {
    color: COLORS.gold,
    textTransform: "uppercase",
  },

  paragraph: {
    flexDirection: "row",
    flexWrap: "wrap",
  },

  heading: {
    color: COLORS.gold,
    textTransform: "uppercase",
  },

  modifier: {
    color: COLORS.gold,
    fontFamily: BOLD_FONT,
    textTransform: "uppercase",
    backgroundColor: "#fff1",
    borderColor: COLORS.gold,
    borderWidth: 1,
    borderRadius: 3,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 6,
    paddingRight: 6,
    verticalAlign: "middle",
    lineHeight: 26,
  },
});

interface ParseResult {
  heading?: boolean;
  modifier?: boolean;
  text?: string;
  token?: string;
}

function renderParses(
  key: number,
  parses: ParseResult[],
  extraTextStyle: TextStyle | undefined,
  headingStyle: TextStyle | undefined
): JSX.Element {
  return (
    <Text key={key} style={[styles.text, styles.paragraph, extraTextStyle]}>
      {parses.map((parse, i) => (
        <Parse
          key={`${i}`}
          parse={parse}
          extraTextStyle={extraTextStyle}
          headingStyle={headingStyle}
        />
      ))}
    </Text>
  );
}

const ICON_TOKENS = {
  air: ICONS.air_damage,
  ap: ICONS.ap,
  b: ICONS.blue_die,
  "direct damage": ICONS.direct_damage,
  earth: ICONS.earth_damage,
  fire: ICONS.fire_damage,
  hits: ICONS.hit,
  hp: ICONS.hp,
  "magic armor": ICONS.magic_armor,
  "physical armor": ICONS.physical_armor,
  poison: ICONS.poison_damage,
  r: ICONS.red_die,
  source: ICONS.source,
  special: ICONS.special,
  target: ICONS.target,
  w: ICONS.white_die,
  water: ICONS.water_damage,
};

function renderToken(
  token: string,
  extraTextStyle: TextStyle | undefined,
  headingStyle: TextStyle | undefined
): JSX.Element {
  // Check for an icon.
  const icon = ICON_TOKENS[token];
  if (icon) {
    return <Image style={styles.icon} source={icon} resizeMode="contain" />;
  }

  // Check for a status.
  const status = STRING_STATUSES.get(token);
  if (status) {
    return (
      <Text style={[styles.text, styles.status, extraTextStyle]}>
        {`${STATUS_STRINGS.get(status)} `}
        <Image
          style={styles.icon}
          source={STATUS_IMAGES.get(status)}
          resizeMode="contain"
        />
      </Text>
    );
  }

  // Fallback for missing implementations.
  return (
    <Text style={[styles.text, styles.token, extraTextStyle]}>{token}</Text>
  );
}

function Parse(props: {
  parse: ParseResult;
  extraTextStyle: TextStyle | undefined;
  headingStyle: TextStyle | undefined;
}): JSX.Element {
  if (props.parse.text) {
    let style: StyleProp<TextStyle> = props.extraTextStyle;
    if (props.parse.heading) {
      style = StyleSheet.compose(
        [styles.heading, props.extraTextStyle],
        props.headingStyle
      );
    }
    if (props.parse.modifier) {
      style = styles.modifier;
    }

    return <Text style={[styles.text, style].flat()}>{props.parse.text}</Text>;
  }
  return renderToken(
    props.parse.token!,
    props.extraTextStyle,
    props.headingStyle
  );
}

function parseLines(lines: string[]): ParseResult[][] {
  return lines.map(parseLine);
}

const tokenPattern = /\[(.*?)\]/g;
function parseLine(text: string): ParseResult[] {
  if (text.startsWith("#")) {
    return [
      {
        heading: true,
        text: text.substring(1),
      },
    ];
  }
  if (text.startsWith("&")) {
    return [{ modifier: true, text: text.substring(1) }];
  }

  const matches = text.matchAll(tokenPattern);
  const results: ParseResult[] = [];
  let previousStart = 0;
  for (const match of matches) {
    const index = match.index;
    results.push({ text: text.substring(previousStart, index) });
    results.push({ token: match[1] });
    previousStart = index + match[0].length;
  }
  const leftOver = text.substring(previousStart, text.length);
  results.push({ text: leftOver });
  return results;
}

function extractExtraTextStyle(
  style?: StyleProp<TextStyle | ViewStyle>
): TextStyle | undefined {
  if (!style) {
    return undefined;
  }
  if (Array.isArray(style)) {
    const textStyles = style.map(extractExtraTextStyle);
    return StyleSheet.flatten(textStyles);
  }
  const styleObj: StyleProp<TextStyle> = {};
  if (style["fontFamily"]) {
    styleObj.fontFamily = style["fontFamily"];
  }
  if (style["fontSize"]) {
    styleObj.fontSize = style["fontSize"];
  }
  if (style["color"]) {
    styleObj.color = style["color"];
  }
  if (style["lineHeight"]) {
    styleObj.lineHeight = style["lineHeight"];
  }
  return styleObj;
}
