export enum StatusEffect {
  BLEEDING,
  BLESSED,
  BLINDED,
  BURNING,
  CHICKEN,
  DOMINATED,
  EXPOSED,
  FROZEN,
  HASTE,
  INVISIBLE,
  KNOCKDOWN,
  POISON,
  SHOCKED,
  STUNNED,
  SUNDERED,
}

export const STATUS_STRINGS: ReadonlyMap<StatusEffect, string> = new Map([
  [StatusEffect.BLEEDING, "Bleeding"],
  [StatusEffect.BLESSED, "Blessed"],
  [StatusEffect.BLINDED, "Blinded"],
  [StatusEffect.BURNING, "Burning"],
  [StatusEffect.CHICKEN, "Chicken!"],
  [StatusEffect.DOMINATED, "Dominated"],
  [StatusEffect.EXPOSED, "Exposed"],
  [StatusEffect.FROZEN, "Frozen"],
  [StatusEffect.HASTE, "Haste"],
  [StatusEffect.INVISIBLE, "Invisible"],
  [StatusEffect.KNOCKDOWN, "Knocked Down"],
  [StatusEffect.POISON, "Poisoned"],
  [StatusEffect.SHOCKED, "Shocked"],
  [StatusEffect.STUNNED, "Stunned"],
  [StatusEffect.SUNDERED, "Sundered"],
]);

export const STRING_STATUSES: ReadonlyMap<string, StatusEffect> = (function () {
  const statusMap = new Map(
    [...STATUS_STRINGS.entries()].map((e) => [e[1].toLowerCase(), e[0]])
  );
  // Need special consideration for poison because it is sometimes used in a different tense.
  statusMap.set("poison", StatusEffect.POISON);
  return statusMap;
})();

export const MINION_STATUSES = [
  StatusEffect.BLEEDING,
  StatusEffect.BLINDED,
  StatusEffect.BURNING,
  StatusEffect.CHICKEN,
  StatusEffect.DOMINATED,
  StatusEffect.EXPOSED,
  StatusEffect.FROZEN,
  StatusEffect.KNOCKDOWN,
  StatusEffect.POISON,
  StatusEffect.SHOCKED,
  StatusEffect.STUNNED,
  StatusEffect.SUNDERED,
] as const;

type Descriptor = string | string[]; // | (() => string) | (() => string[]);
export const STATUS_DESCRIPTIONS: ReadonlyMap<StatusEffect, Descriptor> =
  new Map<StatusEffect, Descriptor>([
    [
      StatusEffect.BLEEDING,
      [
        "At the start of this minion's turn, they take 1 [direct damage].",
        "When they move on their turn, they take [direct damage] equal to the number of spaces moved.",
      ],
    ],
    [
      StatusEffect.BLINDED,
      "This minion's range is reduced to 0. When they attack, the target Origin may reroll 1 of their dice.",
    ],
    [
      StatusEffect.BURNING,
      [
        "At the start of this minion's turn, they take 2 [direct damage].",
        "When they take [fire] damage, extend the duration of [burning] by +2",
      ],
    ],
    [
      StatusEffect.CHICKEN,
      "On this minion's turn, they move away from all Origins as far as they can, and they don't attack or heal.",
    ],
    [
      StatusEffect.DOMINATED,
      "On this minion's turn, you choose their target. You may choose another enemy.",
    ],
    [
      StatusEffect.EXPOSED,
      "When this minion takes damage of any type, they take +1 more.",
    ],
    [
      StatusEffect.FROZEN,
      [
        "This minion's movement is reduced by 1.",
        "They roll -1 die on all attacks.",
        "When they take [fire] damage, remove [frozen].",
      ],
    ],
    [
      StatusEffect.KNOCKDOWN,
      ["This minion can't move.", "Immovable enemies can't gain this status."],
    ],
    [
      StatusEffect.POISON,
      [
        "At the start of this minion's turn, they take 2 [direct damage]. If they are [undead], they instead heal 2 [hp].",
        "They can't heal or be healed unless they are [undead].",
      ],
    ],
    [StatusEffect.SHOCKED, "This minion deals -2 damage on all attacks."],
    [
      StatusEffect.STUNNED,
      [
        "This minion rolls -1 die on all attacks.",
        "They don't heal when they can't attack.",
      ],
    ],
    [StatusEffect.SUNDERED, "This minion has -2 [physical armor]."],
  ]);
