export enum Initiative {
  FAST,
  SLOW,
}

export function getInitiativeLabel(initiative: Initiative): string {
  return initiative === Initiative.FAST ? "Fast" : "Slow";
}

export function fromInitiativeLabel(label: string): Initiative {
  return label.toLowerCase() === "fast" ? Initiative.FAST : Initiative.SLOW;
}

export function toggleInitiative(initiative: Initiative): Initiative {
  return initiative === Initiative.FAST ? Initiative.SLOW : Initiative.FAST;
}
