import { StatusEffect } from "../model/status_effect";

export const STATUS_IMAGES: ReadonlyMap<StatusEffect, any> = new Map([
  [
    StatusEffect.BLEEDING,
    require("../assets/images/status_icons/bleeding.png"),
  ],
  [StatusEffect.BLESSED, require("../assets/images/status_icons/blessed.png")],
  [StatusEffect.BLINDED, require("../assets/images/status_icons/blinded.png")],
  [StatusEffect.BURNING, require("../assets/images/status_icons/burning.png")],
  [StatusEffect.CHICKEN, require("../assets/images/status_icons/chicken.png")],
  [
    StatusEffect.DOMINATED,
    require("../assets/images/status_icons/dominated.png"),
  ],
  [StatusEffect.EXPOSED, require("../assets/images/status_icons/exposed.png")],
  [StatusEffect.FROZEN, require("../assets/images/status_icons/frozen.png")],
  [StatusEffect.HASTE, require("../assets/images/status_icons/haste.png")],
  [
    StatusEffect.INVISIBLE,
    require("../assets/images/status_icons/invisible.png"),
  ],
  [
    StatusEffect.KNOCKDOWN,
    require("../assets/images/status_icons/knockdown.png"),
  ],
  [StatusEffect.POISON, require("../assets/images/status_icons/poison.png")],
  [StatusEffect.SHOCKED, require("../assets/images/status_icons/shocked.png")],
  [StatusEffect.STUNNED, require("../assets/images/status_icons/stunned.png")],
  [
    StatusEffect.SUNDERED,
    require("../assets/images/status_icons/sundered.png"),
  ],
]);
