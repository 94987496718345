export enum Targeting {
  CLOSEST,
  LOWEST_ARMOR,
  HIGHEST_ARMOR,
  LOWEST_MAGIC_ARMOR,
  HIGHEST_MAGIC_ARMOR,
  LOWEST_HP,
  HIGHEST_HP,
  HIGHEST_SOURCE,
  LOWEST_SOURCE,
  MOST_ORIGINS,
  LEAST_ORIGINS,
  SPECIAL,
}
