import interpolate from "color-interpolate";
import { StyleSheet } from "react-native";

export const ACTUAL_IMAGE_WIDTH = 598;
export const ACTUAL_IMAGE_HEIGHT = 840;
export const WH_IMAGE_ASPECT = ACTUAL_IMAGE_WIDTH / ACTUAL_IMAGE_HEIGHT;
export const HW_IMAGE_ASPECT = ACTUAL_IMAGE_HEIGHT / ACTUAL_IMAGE_WIDTH;

export const globalStyles = StyleSheet.create({
  spacer: {
    flex: 1,
  },

  strike: {
    textDecorationLine: "line-through",
  },

  flex1: {
    flex: 1,
  },

  flexRow: {
    flexDirection: "row",
  },
});

export const healthInterpolator = interpolate([
  "#d60000",
  "#ceeb10",
  "#27e359",
]);
