import { SafeAreaView, StyleSheet, Text, View } from "react-native";
import { Initiative } from "../model/initiative";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFastEnemies,
  selectLoading,
  selectSawReleaseNotes,
  selectSlowEnemies,
} from "../store/selectors";
import EnemyList from "./EnemyList";
import AppBar from "./AppBar";
import { LinearGradient } from "expo-linear-gradient";
import { ActivityIndicator, Button, Modal } from "react-native-paper";
import ReleaseNotes from "./ReleaseNotes";
import { actions } from "../store/store";

export default function MainView(): React.JSX.Element {
  const loading = useSelector(selectLoading);
  const fastEnemies = useSelector(selectFastEnemies);
  const slowEnemies = useSelector(selectSlowEnemies);
  const sawReleaseNotes = useSelector(selectSawReleaseNotes) === NOTES_DATE;
  const dispatch = useDispatch();
  const [showReleaseNotes, setShowReleaseNotes] = useState(!sawReleaseNotes);

  return (
    <View style={styles.container}>
      <AppBar onShowReleaseNotes={() => setShowReleaseNotes(true)} />
      <LinearGradient
        style={styles.minions}
        colors={["#7e183f", "#bc5d63"]}
        start={{ x: 0, y: 0 }}
        end={{ x: 0, y: 1 }}
      >
        <View style={styles.speed}>
          <View style={styles.speedContainer}>
            <Text style={styles.speedLabel} numberOfLines={1}>
              Fast
            </Text>
          </View>
          <EnemyList
            style={styles.enemyList}
            enemies={fastEnemies}
            initiative={Initiative.FAST}
          />
        </View>

        <View style={styles.speed}>
          <View style={styles.speedContainer}>
            <Text style={styles.speedLabel} numberOfLines={1}>
              Slow
            </Text>
          </View>
          <EnemyList
            style={styles.enemyList}
            enemies={slowEnemies}
            initiative={Initiative.SLOW}
            showSelectorFab={true}
          />
        </View>
      </LinearGradient>
      {loading && (
        <View style={styles.loading}>
          <ActivityIndicator animating={true} color="blue" size={150} />
        </View>
      )}

      <Modal
        visible={showReleaseNotes}
        style={styles.modal}
        contentContainerStyle={styles.modalContainer}
        onDismiss={() => {
          dispatch(actions.sawReleaseNotes({ notes: NOTES_DATE }));
          setShowReleaseNotes(false);
        }}
      >
        {showReleaseNotes && (
          <View style={styles.notes}>
            <ReleaseNotes />
            <Button
              onPress={() => {
                dispatch(actions.sawReleaseNotes({ notes: NOTES_DATE }));
                setShowReleaseNotes(false);
              }}
            >
              Close
            </Button>
          </View>
        )}
      </Modal>
    </View>
  );
}

const NOTES_DATE = "2024-05-28";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },

  minions: {
    flex: 1,
    gap: 5,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 5,
  },

  speed: {
    flex: 1,
    flexDirection: "row",
  },

  speedContainer: {
    width: 50,
    justifyContent: "center",
  },

  speedLabel: {
    fontSize: 30,
    width: 70,

    overflow: "visible",
    // Need the extra translation for iOS only, but seems ok on web
    transform: [{ rotate: "-90deg" }, { translateY: -10 }],
  },

  enemyList: {
    flex: 1,
  },

  loading: {
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.6)",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
  },

  modal: {
    justifyContent: "flex-start",
    alignItems: "center",
  },

  modalContainer: {
    height: "75%",
  },

  notes: {
    flex: 1,
    backgroundColor: "white",
    marginTop: "20%",
  },
});
