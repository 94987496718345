import { AttackType } from "./attack_type";
import { DamageType } from "./damage_type";
import { DieRoll } from "./dice";
import { Expansion } from "./expansion";
import { Targeting } from "./targeting";

export type EnemyType = "boss";

export interface BaseEnemy {
  id: string;
  name: string;
  level: number;
  expansion?: Expansion;
  type?: EnemyType;
}

export interface Enemy extends BaseEnemy {
  level: number;
  hp: number;
  armor: number;
  magicArmor: number;
  targeting: Targeting;
  move: number;
  range?: number;
  attackType: AttackType;
  attackRoll: DieRoll;
  damageType: DamageType;
  veteran: boolean;
  text: string[];
}

export function isEnemy(obj: any): obj is Enemy {
  return !Array.isArray(obj.hp);
}
