import { PayloadAction, configureStore, createSlice } from "@reduxjs/toolkit";
import { EnemyState, Game, Letter } from "../model/game";
import { Initiative, toggleInitiative } from "../model/initiative";
import { getEnemy, getModifier } from "../db";
import { StatusEffect } from "../model/status_effect";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { isEnemy } from "../model/enemy";

function ensureNotLoading(game: Game): void {
  if (game.loading) {
    throw new Error("Cannot dispatch actions while the game is loading.");
  }
}

function enemyArray(game: Game, initiative: Initiative): EnemyState[] {
  return initiative === Initiative.FAST ? game.fast : game.slow;
}

function enemyAt(
  game: Game,
  initiative: Initiative,
  index: number
): EnemyState {
  const array = enemyArray(game, initiative);
  if (!array || array[index] === undefined) {
    throw new Error(`No enemy at index ${index} for initative ${initiative}`);
  }
  return array[index];
}

const gameSlice = createSlice({
  name: "game",
  initialState: {
    loading: true,
    time: 0,
    fast: [],
    // fast: [
    //   {
    //     cooldowns: [
    //       { status: StatusEffect.BLINDED, turns: 2 },
    //       { status: StatusEffect.BURNING, turns: 2 },
    //       { status: StatusEffect.POISON, turns: 2 },
    //       { status: StatusEffect.EXPOSED, turns: 2 },
    //       { status: StatusEffect.FROZEN, turns: 2 },
    //       { status: StatusEffect.STUNNED, turns: 2 },
    //     ],
    //     currentHp: 5,
    //     id: "wolf",
    //     letter: "B",
    //   },
    //   { cooldowns: [], currentHp: 15, id: "veteran_ruffian", letter: "D" },
    // ],
    slow: [],
  } as Game,
  reducers: {
    load: (
      game,
      action: PayloadAction<{
        game: Omit<Game, "loading">;
      }>
    ) => {
      Object.assign(game, action.payload.game);
      delete game.loading;
    },

    addEnemy: (
      game,
      action: PayloadAction<{
        letter: Letter;
        initiative: Initiative;
        id: string;
        maxHp?: number;
      }>
    ) => {
      const { initiative, id, letter } = action.payload;
      let { maxHp } = action.payload;

      const enemy = getEnemy(id);
      if (maxHp === undefined) {
        if (isEnemy(enemy)) {
          maxHp = enemy.hp;
        } else {
          throw new Error("No max HP specified for boss.");
        }
      }
      const instance: EnemyState = {
        id: enemy.id,
        letter,
        currentHp: maxHp,
        maxHp,
        cooldowns: [],
      };
      enemyArray(game, initiative).push(instance);
    },

    setHp: (
      game,
      action: PayloadAction<{
        initiative: Initiative;
        index: number;
        hp: number;
      }>
    ) => {
      const { initiative, index, hp } = action.payload;
      enemyAt(game, initiative, index).currentHp = hp;
    },

    swapInitiative: (
      game,
      action: PayloadAction<{ initiative: Initiative; index: number }>
    ) => {
      const { initiative, index } = action.payload;
      const enemy = enemyAt(game, initiative, index);

      const newInitiative = toggleInitiative(initiative);
      if (initiative === Initiative.FAST) {
        game.fast.splice(index, 1);
        game.slow.push(enemy);
      } else {
        game.slow.splice(index, 1);
        game.fast.push(enemy);
      }
    },

    removeEnemy: (
      game,
      action: PayloadAction<{ initiative: Initiative; index: number }>
    ) => {
      const { initiative, index } = action.payload;
      const array = enemyArray(game, initiative);
      if (!array || array[index] === undefined) {
        throw new Error(
          `No enemy at index ${index} for initative ${initiative}`
        );
      }
      array.splice(index, 1);
    },

    addCooldown: (
      game,
      action: PayloadAction<{
        initiative: Initiative;
        index: number;
        status: StatusEffect;
        turn: number;
      }>
    ) => {
      const { initiative, index, status, turn } = action.payload;
      const enemy = enemyAt(game, initiative, index);

      // First, remove any cooldowns of that type.
      const cooldownIndex = enemy.cooldowns.findIndex(
        (c) => c.status === status
      );
      if (cooldownIndex >= 0) {
        enemy.cooldowns.splice(cooldownIndex, 1);
      }
      enemy.cooldowns.push({ status, turns: turn });
    },

    removeCooldown: (
      game,
      action: PayloadAction<{
        initiative: Initiative;
        index: number;
        status: StatusEffect;
      }>
    ) => {
      const { initiative, index, status } = action.payload;
      const enemy = enemyAt(game, initiative, index);
      const cooldownIndex = enemy.cooldowns.findIndex(
        (c) => c.status === status
      );
      if (cooldownIndex >= 0) {
        enemy.cooldowns.splice(cooldownIndex, 1);
      }
    },

    advanceCooldowns: (
      game,
      action: PayloadAction<{ initiative: Initiative; index: number }>
    ) => {
      const { initiative, index } = action.payload;
      const enemy = enemyAt(game, initiative, index);
      const cooldowns = enemy.cooldowns;

      for (let i = cooldowns.length - 1; i >= 0; --i) {
        const cooldown = cooldowns[i];
        if (cooldown.turns === 1) {
          // Remove if at 1.
          cooldowns.splice(i, 1);
        } else {
          // Decrement if not.
          cooldown.turns--;
        }
      }
    },

    clearAll: (game) => {
      game.fast = [];
      game.slow = [];
      game.time = 0;
    },

    sawReleaseNotes: (game, action: PayloadAction<{ notes: string }>) => {
      game.sawReleaseNotes = action.payload.notes;
    },

    addModifier: (
      game,
      action: PayloadAction<{
        initiative: Initiative;
        index: number;
        modifierId: string;
      }>
    ) => {
      const { initiative, index, modifierId } = action.payload;
      const enemy = enemyAt(game, initiative, index);
      // This just makes sure the modifier exists!
      getModifier(modifierId);
      if (enemy.modifiers === undefined) {
        enemy.modifiers = [];
      }
      enemy.modifiers.push(modifierId);
    },

    removeModifier: (
      game,
      action: PayloadAction<{
        initiative: Initiative;
        index: number;
        modifierId: string;
      }>
    ) => {
      const { initiative, index, modifierId } = action.payload;
      const enemy = enemyAt(game, initiative, index);
      const modIndex = enemy.modifiers?.indexOf(modifierId) ?? -1;
      if (modIndex >= 0) {
        enemy.modifiers.splice(modIndex, 1);
      }
    },
  },
});

export const store = configureStore({ reducer: gameSlice.reducer });

export const actions = {
  ...gameSlice.actions,
} as const;

const STORAGE_KEY = "DivinityGame";
async function initializeStore(): Promise<void> {
  // Uncomment this to purge the store on the device. DO NOT launch with this!
  // await AsyncStorage.clear();
  store.subscribe(() => {
    const state = store.getState();
    AsyncStorage.setItem(STORAGE_KEY, JSON.stringify(state));
  });

  const storedSettings = await AsyncStorage.getItem(STORAGE_KEY);
  let game = { ...store.getState() };

  if (storedSettings) {
    game = JSON.parse(storedSettings);
  }
  store.dispatch(actions.load({ game }));
}

initializeStore();
