export const COLORS = {
  black: "#000",
  white: "#fff",
  darkRed: "#942210",
  gold: "gold",
  lightGold: "#ffe866",

  darkShadow: "#999",

  darkBorder: "#c79648",
  lightBorder: "#eae8b5",
} as const;
