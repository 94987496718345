import {
  ImageBackground,
  StyleProp,
  StyleSheet,
  View,
  ViewProps,
  useWindowDimensions,
} from "react-native";
import { EnemyState } from "../model/game";
import { Button, Icon, Text } from "react-native-paper";
import { STATUS_DESCRIPTIONS, STATUS_STRINGS } from "../model/status_effect";
import { STATUS_IMAGES } from "../bundles/status_images";
import RichText from "./RichText";
import MinionCard from "./MinionCard";
import {
  getInitiativeLabel,
  Initiative,
  toggleInitiative,
} from "../model/initiative";
import MinionMarker from "./MinionMarker";
import { useState } from "react";
import { useSelector, useStore } from "react-redux";
import { selectFastEnemies, selectSlowEnemies } from "../store/selectors";
import { MAIN_FONT } from "../bundles/fonts";
import { COLORS } from "./colors";
import { MinionObj } from "./minion_obj";
import { getEnemy } from "../db";

interface Props {
  style?: StyleProp<ViewProps>;
  //   minion: MinionObj;
  index: number;
  initiative: Initiative;
}

export default function FullCardView(props: Props): JSX.Element {
  const { style } = props;
  const [currentEnemy, setCurrentEnemy] = useState({
    //   minion: props.minion,
    index: props.index,
    initiative: props.initiative,
  });
  const fastEnemies = useSelector(selectFastEnemies);
  const slowEnemies = useSelector(selectSlowEnemies);
  const { index, initiative } = currentEnemy;
  const dimensions = useWindowDimensions();

  const eState =
    initiative === Initiative.FAST ? fastEnemies[index] : slowEnemies[index];
  const minion = new MinionObj(getEnemy(eState.id), eState);

  const imageHeight = dimensions.height * 0.9;
  const sortedCooldowns = [...minion.cooldowns];
  sortedCooldowns.sort((a, b) => b.turns - a.turns);

  function setEnemy(i: number): void {
    let currInitiative = initiative;
    let currInit = initiative === Initiative.FAST ? fastEnemies : slowEnemies;
    let otherInit = initiative === Initiative.FAST ? slowEnemies : fastEnemies;

    function swapInits() {
      const swap = currInit;
      currInit = otherInit;
      otherInit = swap;
      currInitiative = toggleInitiative(currInitiative);
    }

    if (i < 0) {
      if (otherInit.length) {
        i = otherInit.length + i;
        swapInits();
      } else {
        i = currInit.length - 1;
      }
    } else if (i >= currInit.length) {
      if (otherInit.length) {
        i -= currInit.length;
        swapInits();
      } else {
        i = 0;
      }
    }

    if (i >= currInit.length) {
      // Do nothing if there's nothing to swap to.
      return;
    }

    // const newEnemyState = currInit[i];
    const newEnemy = {
      //   minion: new MinionObj(getEnemy(newEnemyState.id), newEnemyState),
      index: i,
      initiative: currInitiative,
    };
    setCurrentEnemy(newEnemy);
  }

  return (
    <View>
      <Text style={styles.initiative}>
        {initiativeIcon(initiative)}
        {"  "}
        {getInitiativeLabel(initiative)}
        {"  "}
        {initiativeIcon(initiative)}
      </Text>
      <Button
        style={[styles.indexButton, { left: 0 }]}
        contentStyle={styles.indexButtonContent}
        labelStyle={styles.indexButtonLabel}
        onPress={() => setEnemy(index - 1)}
      >
        &lt;
      </Button>
      <Button
        style={[styles.indexButton, { right: 0 }]}
        contentStyle={styles.indexButtonContent}
        labelStyle={styles.indexButtonLabel}
        onPress={() => setEnemy(index + 1)}
      >
        &gt;
      </Button>
      <View style={[style, styles.container]}>
        <View style={styles.leftSide}>
          <MinionMarker minion={minion} index={index} initiative={initiative} />
          <View style={[styles.cardContainer, { height: imageHeight }]}>
            <MinionCard
              style={[styles.card]}
              minion={minion}
              index={index}
              initiative={initiative}
            />
          </View>
        </View>
        <View style={styles.cooldowns}>
          {sortedCooldowns.map((c) => (
            <View key={`${c.status}`} style={styles.status}>
              <ImageBackground
                style={styles.left}
                source={STATUS_IMAGES.get(c.status)}
                imageStyle={styles.statusIcon}
                resizeMode="contain"
              >
                <Text style={styles.title}>{STATUS_STRINGS.get(c.status)}</Text>
                <Text style={styles.turns}>{c.turns} turns</Text>
              </ImageBackground>
              <View style={styles.right}>
                <RichText
                  style={styles.description}
                  text={STATUS_DESCRIPTIONS.get(c.status)}
                />
              </View>
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: { flexDirection: "row", backgroundColor: "rgba(0,0,0,0.8)" },

  initiative: {
    flex: 1,
    fontFamily: MAIN_FONT,
    fontSize: 30,
    textAlign: "center",
    backgroundColor: COLORS.darkRed,
    color: COLORS.white,
    padding: 8,
  },

  image: { flex: 1 },

  leftSide: { width: 500 },

  cardContainer: {},

  card: {
    transformOrigin: "top left",
    transform: [{ scale: 1.8 }],
  },

  cooldowns: { flex: 1, gap: 3 },

  status: { flexDirection: "row", gap: 3 },

  statusIcon: {
    opacity: 0.7,
  },

  left: {
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 5,
    paddingRight: 10,
    flex: 1,
  },

  title: {
    color: "white",
    fontSize: 25,
    textShadowColor: "black",
    textShadowRadius: 3,
  },

  indexButton: {
    backgroundColor: COLORS.gold,
    position: "absolute",
    margin: 8,
  },
  indexButtonContent: {},
  indexButtonLabel: {
    fontSize: 30,
  },

  turns: { color: "white", textShadowColor: "black", textShadowRadius: 3 },

  right: { flex: 4, justifyContent: "center" },

  description: { color: "white" },
});

function initiativeIcon(initiative: Initiative): JSX.Element {
  return (
    <Icon
      color={COLORS.white}
      size={30}
      source={initiative === Initiative.FAST ? "rabbit" : "tortoise"}
    />
  );
}
