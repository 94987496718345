import { Boss } from "./model/boss";
import { BaseEnemy, Enemy } from "./model/enemy";
import { Modifier } from "./model/modifier";

function loadEnemies(): Map<string, Enemy | Boss> {
  const json = require("./assets/data/enemies.json");

  return new Map(Object.entries(json));
}

const allEnemies = loadEnemies();

export function getEnemy(id: string): Enemy | Boss {
  const enemy = allEnemies.get(id);
  if (!enemy) {
    throw new Error(`No enemy found for id "${id}"`);
  }
  return enemy;
}

export function getAllEnemies(): (Enemy | Boss)[] {
  return [...allEnemies.values()];
}

function loadModifiers(): Map<string, Modifier> {
  const json = require("./assets/data/modifiers.json");

  return new Map(Object.entries(json));
}

const allModifiers = loadModifiers();

export function getModifier(id: string): Modifier {
  const modifier = allModifiers.get(id);
  if (!modifier) {
    throw new Error(`No modifier found for id ${id}.`);
  }
  return modifier;
}

export function getAllModifiers(): Modifier[] {
  return [...allModifiers.values()];
}
